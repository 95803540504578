:root{
  color: #1A1540;
}

// $hl: #BAD531;
// $hlshadow: #81AE64;

$hl: #b5c427;
$hlshadow: #8da355;




// $white: #F2F7E6;
// $whiteshadow: #C5E5D8;

$white: #ede7be;
$whiteshadow: #d1d4a1;

$black: #1A1540;


// #698CC2
// #2D8A50

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $hl;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.paragraph{
  font-size: clamp(16px, 4vw, 2rem);
  font-family: "Roboto";
  line-height: 2em;
}

.markDown{
  margin: 1rem;
  color: #1A1540;
  h3{
    font-size: 25px;
    font-weight: bold;
    color: #1A1540;
    margin-top: 2rem;
  }

  ul{
    list-style-type: disc;

    li{
      color: #1A1540;
      margin-top: 0.5rem;
    }
  }
}

.centerText{
  text-align: center;
}

.zcardlist{

// margin: 2rem;
//   background-color: #1A1540;

//   display: flex;
//   flex-wrap: wrap;
//   // justify-content: center;
//   justify-content: center;
//   flex-direction: row;

display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-evenly;

}

.bigCard
{
  @extend .zcard;
  width: 80%;
  margin-left: 0;
  margin-right: 0;
  background-color: red ;
  .zcardimg{
    width: 40vw;
  }

}

.zcard{
  @extend .greencard;



  // margin-left: clamp(20px, 1vw, 100px);
  // margin-right: clamp(20px, 1vw, 100px);

    // margin: 1rem;
    // min-width: 200px;
    margin-bottom: 5vh;
    width: clamp(25vw, 300px, 95%);
    // min-width: 200px;

    margin-left: 1vw;
    margin-right: 1vw;


    display: flex;
    flex-direction: column;
    align-items: center;

  transition: transform .2s ease-in-out;

    &:hover {
      transform: scale(1.01);

    }

    // margin-left: clamp(20px, 1vw, 100px);
    // margin-right: clamp(20px, 1vw, 100px);
    


    .zcardimg {
      width: auto;
      max-width: 90%;
      // width: auto;
      max-height: 90vh;
      // max-width: 95%;
      
      min-width: 200px;
      border-radius: 0.5rem;
      margin-top: 1rem;
      filter: drop-shadow(0px 0px 10px $hlshadow);
      // margin-left: 1rem;
      // margin-right: 1rem;

    }

    .zcardlabel {
      @extend .whitecard;
      margin-top: 1rem;
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      // white-space: nowrap;

      .zcardtitle {
        // @extend .bigtext;
        font-family: "Roboto";
        text-align: center;
        
        font-size: clamp(16px, 4vw, 2rem);


        text-align: center;
        margin-top: 1rem;

      }
    }

}

.projectDescription{
  font-family: "Roboto";
  text-align: center;
  font-size: 1.5rem;
  font-style: italic;
}

.Navbar {
  margin-bottom: 4rem;

  

  display: flex;
  flex-direction: row;
  align-items: center;

  .linklist {
    list-style: none;
    display:block;
    margin-left: auto;
    .navlinkitem{
      display:block;
      list-style: none;
    }
  }

    .linksdiv {

      // @extend .card;
      @extend .whitecard;

      margin-left: auto;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      .pagelink {
        transition: transform .2s ease-in-out;

        &:hover {
          transform: scale(1.1);

        }

        $linkspacing: 5.0vw;

        @extend .bigtext;
        margin-left: $linkspacing;
        
        

        h2{
          font-size: 1.9rem;
        }

        &:last-child {
          margin-right: $linkspacing;
        }

      }
    }
  

  .img {
    @extend .whitecard;

    width: clamp(8vw, 30px, 80%);
    padding: 0.5rem;

    transition: transform .2s ease-in-out;

    &:hover {
      transform: scale(1.05);

    }
  }


}

.footerContent {
  margin-top:auto;
  display: flex;
  justify-content: space-between;

  margin: 1rem;
}

.socialLink{
  display: flex;
}

.instagramIcon{
  width: 2rem;
  height: 2rem;

  padding: 0.5rem;
}

.itchIcon{
  width: 2rem;
  height: 2rem;

  padding: 0.5rem;
}

.mainAndFooterWrapper{
  

  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.aligncenter{
  text-align: center;
}

.slidesContainer
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// .designList{
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;

  
// }

// .designItem{
//   @extend .greencard;
//   margin: 2rem;
//   word-wrap: break-word;
//   max-width: 25vw;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   padding: 1rem;
//   .img{
//     max-width: 20vw;
//     min-width: 300px;
//     width: auto;
//     margin-left: auto;
//     margin-right: auto;

//     filter: drop-shadow(0px 0px 10px $hlshadow);
//     padding: 1rem;
//   }

//   .designTitle{
//     word-wrap: break-word;
//     @extend .whitecard;
//   }
// }


.gameregion {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 2rem;

  .startgamebutton {
    @extend .greencard;

    border: none;

    margin-left: auto;
    margin-right: auto;

    // margin: 10rem;


    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .gamepagethumb {
      margin: 2rem;
      width: 30vw;
    }
  }
}


.content {
  margin: 2rem;
}



//text
.text {
  color: $black;
  text-decoration: none;

}

.bigtext {
  @extend .text;
  
  font-family: 'Bowlby One SC';


}


a {
  color: $black;
  text-decoration: none;

  &:hover {
    color: $black;
  }
}


//cards
.card {
  border-radius: 1.5rem;
  // margin: 2rem;
  padding: 1rem;

}

.whitecard {
  @extend .card;
  background-color: $white;
  box-shadow: -5px 5px 4px 3px $hlshadow;
}

.greencard {
  @extend .card;
  background-color: $hl;
  box-shadow: -5px 5px 4px 3px $whiteshadow;
}

.landingFrog{
  width: clamp(15vw, 100px, 500px);
  margin: 2rem;

}

.divAlignCenter{
  display: flex;
  align-items: center;
  flex-direction: column;

}

@font-face {
  font-family: 'Bowlby One SC';
  font-style: normal;
  font-weight: 400;
  src: local('Bowlby One SC Regular'), local('BowlbyOneSC-Regular'), url(http://themes.googleusercontent.com/static/fonts/bowlbyonesc/v5/8ZkeXftTuzKBtmxOYXoReWwgCUWMlcoGHIqZ0R9q_Yo.ttf) format('truetype');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: local('Titillium Web'), local('TitilliumWeb-Regular'), url(http://themes.googleusercontent.com/static/fonts/titilliumweb/v1/7XUFZ5tgS-tD6QamInJTcU3KvHLhcNjEHFQzwNtdMQY.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito-Regular'), url(http://themes.googleusercontent.com/static/fonts/nunito/v4/kpI87QY2ce-mk2ZnKb-r0g.ttf) format('truetype');
}